import React from 'react';

const Calendly = () => (
  <div style={{ height: '800px' }}>
    <iframe
      title="Schedule"
      src="https://calendly.com/donmiller/web-consultation"
      width="100%"
      height="100%"
      frameBorder="0"
    />
  </div>
);

export default Calendly;
