import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import Calendly from '../../components/Calendly';

const Schedule = () => (
  <Layout bodyClass="page-contact">
    <SEO title="Schedule" />
    <div className="container">
      <div className="row">
        <div className="col-12">
          <Calendly />
        </div>
      </div>
    </div>
  </Layout>
);

export default Schedule;
